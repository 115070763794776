/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React, {lazy} from 'react';
import ProtectedRoute from './ProtectedRoute.jsx';

import App from '../App.jsx'
import HomePage from '../pages/sportCategory/homePage/index.jsx'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const { PUBLIC_URL } = import.meta.env;


// Pages with Important Data, These pages contain data that's essential to the core functionality of our application
import Explore from '../pages/sportCategory/explore/Explore'
import Nft from "../pages/sportCategory/nft/Nft"
import User from "../pages/sportCategory/user/User"
import Profile from "../pages/sportCategory/profile/Profile"
import Learning from "../pages/sportCategory/learning/index"

export default function AppRoutes() {

    // Static pages
    const Faq = lazy(() => import('../pages/sportCategory/faq/Faq'));
    const TermsOfService = lazy(() => import('../pages/sportCategory/termsOfService/TermsOfService'));
    const AboutUs = lazy(() => import('../pages/sportCategory/about-Us/AboutUs'));
    const AboutNFT = lazy(() => import('../pages/sportCategory/about-nft/index'));
    const ContactUs = lazy(() => import('../pages/sportCategory/contact-us/index'));
    const Collab = lazy(() => import('../pages/sportCategory/collab/index'));


    // Pages with Minimal Data
    const ClubsLanding = lazy(() => import('../pages/sportCategory/clubsLanding/index'));
    const ClubsList = lazy(() => import('../pages/sportCategory/ClubsList/index'));
    const Club = lazy(() => import('../pages/sportCategory/club/index'));
    const PaymentReceipt = lazy(() => import('../pages/sportCategory/paymentReceipt/index'));
    const MomentCertificate = lazy(() => import('../pages/sportCategory/momentCertificate/index.jsx'));
    const CatalogQrCodeVoice = lazy(() => import('../pages/sportCategory/catalogQrCodeVoice/index'));
    const Prediction = lazy(() => import('../pages/sportCategory/prediction/index'));
    const FCGift = lazy(() => import('../pages/sportCategory/fc-gift/index'));
    const WaitList = lazy(() => import('../pages/sportCategory/waitList/index'));


    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App/>}>
                    <Route path="/" element={<HomePage/>}/>

                     {/*Pages with Minimal Data*/}
                    <Route path="/clubs-landing" element={<ClubsLanding/>}/>
                    <Route path="/clubs" element={<ClubsList/>}/>
                    <Route path="/clubs/:id" element={<Club/>}/>
                    <Route path="/payment-receipt/:id" element={<PaymentReceipt/>}/>
                    <Route path="/moment-certificate/:momentId" element={<MomentCertificate/>}/>
                    <Route path="/moment-certificate/:momentId/editions/:editionId" element={<MomentCertificate/>}/>
                    <Route
                        path="/catalog-qrcode-voice/1709116762409-iNCF7yAfXWXTiLzjazMxH7eoiryift0685ygRMsVXZbDdtyOJTgLe18jCr00b2DPko5ats2xLRNBoQ9QdB5M6yqF9qy1IQdhqwxm"
                        element={<CatalogQrCodeVoice/>}/>
                    <Route path="/prediction" element={<Prediction/>}/>
                    <Route path="/fc-gift" element={<FCGift/>}/>
                    <Route path="/waitlist" element={<WaitList/>}/>


                    {/*static pages*/}
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/terms-of-service" element={<TermsOfService/>}/>
                    <Route path="/about-us" element={<AboutUs/>}/>
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="/about-nft" element={<AboutNFT/>}/>
                    <Route path="/collab" element={<Collab/>}/>


                     {/*Pages with Important Data, These pages contain data that's essential to the core functionality of our application*/}
                    <Route path="/explore" element={<Explore/>}/>
                    <Route path="/nfts/:id" element={<Nft/>}/>
                    <Route path="/nfts/:id/editions/:editionId" element={<Nft/>}/>
                    <Route path="/users/:username/*" element={<User/>}/>
                    <Route
                        path="/profile/*"
                        element={(
                            <ProtectedRoute>
                                <Profile/>
                            </ProtectedRoute>
                        )}
                    />
                    <Route path="/learning" element={<Learning/>}/>

                </Route>
            </Routes>
        </BrowserRouter>
    );
}
